<template>
  <div class="AssociatedEnterprise">
    <div v-if="userInfo.manuId">
      <div class="titles">我的企业</div>
      <div class="type1">
        <img
          src="@/assets/images/AccountSettings/yesAdd.png"
          style="width: 80px; height: 80px"
          alt=""
        />
        <div class="gree">
          <img
            src="@/assets/images/AccountSettings/haves.png"
            style="margin-right: 10px"
            alt=""
          />
          <span>
            {{ userInfo.manuName }}
          </span>
        </div>
        <div class="name">
          <div><span class="tit">管理员名称 ：</span> {{ adminName }}</div>
          <div>
            <span class="tit">我的角色 ：</span>{{ userInfo.roles[0].roleName }}
          </div>
        </div>

        <el-button
          style="font-size: 16px; padding: 10px 20px; color: rgba(0, 0, 0, 60%)"
          @click="exit"
          >退出企业</el-button
        >
      </div>
    </div>

    <div v-else>
      <div class="titles">我的企业</div>
      <div class="type1">
        <img
          src="@/assets/images/AccountSettings/noAdd.png"
          style="width: 80px; height: 80px"
          alt=""
        />
        <div class="yell">
          <img
            src="@/assets/images/AccountSettings/noHaves.png"
            style="margin-right: 10px"
            alt=""
          />
          <span>您还没有和企业建立联系</span>
          </div>
        <el-button
          style="font-size: 16px; padding: 10px 20px"
          @click="dialogVisible = true"
          type="primary"
          >创建企业</el-button
        >
      </div>
      <div class="titles">邀请我的企业</div>
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="{
          background: '#F5F5F5',
          fontWeight: 600,
          fontSize: '14px',
          color: '#000000d9',
        }"
      >
        <el-table-column prop="manufactoryName" label="企业名称">
        </el-table-column>
        <el-table-column prop="realName" label="邀请人" width="180">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button type="text" @click="yesClick(scope.row.id)"
              >同意</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog title="创建企业" :visible.sync="dialogVisible" width="600px">
      <el-form
        :model="ruleForm"
        status-icon
        :rules="rules"
        ref="ruleForm"
        label-width="80px"
        class="demo-ruleForm"
      >
        <el-form-item label="企业名称" prop="name">
          <el-input v-model="ruleForm.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="统一社会信用代码" prop="manufactoryCode">
          <el-input
            v-model="ruleForm.manufactoryCode"
            autocomplete="off"
          ></el-input>
        </el-form-item>

        <el-form-item label="企业类型" prop="factoryType">
          <el-select v-model="ruleForm.factoryType" placeholder="请选企业类型">
            <el-option
              v-for="item in dict.sys_manu_type"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="联系电话" prop="contactPhone">
          <el-input
            v-model="ruleForm.contactPhone"
            autocomplete="off"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系地址" prop="addressArr">
          <el-cascader
            style="width: 100%"
            v-model="ruleForm.addressArr"
            :options="$store.state.area.areaList"
          />
        </el-form-item>
        <el-form-item style="display: flex; justify-content: end">
          <el-button type="primary" @click="submit" style="width: 95px"
            >确认创建</el-button
          >
          <el-button @click="resetForm('ruleForm')" style="width: 95px"
            >取消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { useDict } from "@/utils/dict";
import { mapState } from "vuex";
import {
  systemManu,
  associationManuInfo,
  inviteManu,
  associationManu,
  exitManu,
} from "@/api/personnelManagement";
export default {
  name: "AssociatedEnterprise",
  data() {
    let checkPhone = (rule, value, callback) => {
      // 手机号验证
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3456789]\d{9}$/;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的手机号"));
        }
      }
    };
    let checkManufactoryCode = (rule, value, callback) => {
      // 信用代码
      if (!value) {
        return callback(new Error("社会信用代码不能为空"));
      } else {
        const reg = /^[^_IOZSVa-z\W]{2}\d{6}[^_IOZSVa-z\W]{10}$/g;
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error("请输入正确的社会信用代码"));
        }
      }
    };
    return {
      tableData: [],
      dialogVisible: false,
      ruleForm: {
        name: "",
        manufactoryCode: "",
        factoryType: "",
        contactPhone: "",
        addressArr: null,
      },
      adminName: "",
      dict: {},
      rules: {
        name: [
          { required: true, message: "请输入企业名称", trigger: "blur" },
          {
            min: 2,
            max: 30,
            message: "长度在 2 到 30 个字符",
            trigger: "blur",
          },
        ],
        contactPhone: [
          { required: true, validator: checkPhone, trigger: "blur" },
        ],
        manufactoryCode: [
          { required: true, validator: checkManufactoryCode, trigger: "blur" },
        ],
        factoryType: [
          { required: true, message: "请输入企业类型", trigger: "blur" },
        ],
        addressArr: [
          { required: true, message: "请选择地区", trigger: "blur" },
        ],
      },
    };
  },
  mounted() {
    //获取企业信息
    if (this.userInfo.manuId) {
      this.getAdminName();
    } else {
      this.getList();
    }

    this.dict = useDict("sys_manu_type");
  },
  methods: {
    async getAdminName() {
      let res = await associationManuInfo();
      if (res.code == 200) {
        this.adminName = res.data.adminName;
      }
    },
    async getList() {
      let res = await inviteManu();
      if (res.code == 200) {
        this.tableData = res.data;
      }
    },
    yesClick(id) {
      this.$confirm("是否要同意该企业邀请?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          associationManu({ id }).then(({ code, msg }) => {
            if (code == 200) {
              this.$message.success(msg);
              this.getAdminName();
              this.$store.dispatch("getUserInfo");
            } else {
              this.$message.error(msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
    exit() {
      this.$confirm("是否要退出该企业?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          exitManu({ id: this.userInfo.manuId }).then(({ code, msg }) => {
            if (code == 200) {
              this.$message.success(msg);
              this.$store.dispatch("getUserInfo");
              this.getList();
            } else {
              this.$message.error(msg);
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },

    submit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let diZhi = JSON.parse(JSON.stringify(this.ruleForm.addressArr));
          diZhi = diZhi.join("-");
          this.ruleForm.address = diZhi;
          this.ruleForm.contactPeople = this.ruleForm.contactPhone;
          systemManu(this.ruleForm).then(({ code, msg }) => {
            if (code == 200) {
              this.$message.success(msg);
              this.dialogVisible = false;

              this.$store.dispatch("getUserInfo");
              this.getAdminName();
            } else {
              this.$message.error(msg);
            }
          });
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false;
    },
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
};
</script>

<style lang="less" scoped>
.AssociatedEnterprise {
  margin-top: 20px;
  margin-left: 30px;
  // width: 800px;
  /deep/.el-table__row {
    color: rgba(0, 0, 0, 85%);
  }
  .titles {
    font-size: 16px;
    height: 20px;
    line-height: 20px;
    margin: 10px 0;
    font-weight: 600;
    padding-left: 10px;
    border-left: 6px solid #0dac65;
    color: rgba(0, 0, 0, 85%);
  }
  /deep/.el-form-item__label {
    display: flex;
    line-height: 1.2em;
    align-items: center;
    height: 40px;
  }
  .type1 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    .yell {
      color: #ff7f00;
      line-height: 40px;
      height: 50px;
      font-weight: 600;
      font-size: 16px;
      display: flex;
      align-items: center;
    }
    .gree {
      color: rgba(0, 0, 0, 85%);
      line-height: 40px;
      height: 50px;
      font-weight: 600;
      font-size: 16px;
      display: flex;
      align-items: center;
    }
    .name {
      width: 300px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px;
      font-size: 14px;
      color: rgba(0, 0, 0, 85%);
      .tit {
        color: #999;
      }
    }
  }
}
</style>
